
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import Footer from '@/components/landing/footer/Footer'
import Navbar from '@/components/ui/Navbar'
import { ImageNotFound } from '@/res/images'

const PageNotFound = () => {
  return (
    <>
      <Navbar notShowMenuIcon />
      <div className="max-h-screen h-full max-w-screen">
        <div className="small:max-w-[55%] small:mt-[72px] mt-20 mx-auto w-full">
          <img
            src={ImageNotFound.src}
            alt="page not found"
            className="w-full h-full"
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default PageNotFound


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  